@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.layout-copyright {
    font-family: Josefin Sans, serif;
    font-weight: 450;
    padding-bottom: 5px;
    color: rgb(128, 128, 128);
}

.fade-animation {
    animation: fade-in 1s ease-out forwards;
}

.nav-word {
    color: rgb(0, 51, 179);
    font-size: 25px;
    font-weight: 600;
    font-family: Josefin Sans, serif;
    text-decoration: none;
}

.nav-word-name {
    font-size: 25px;
    font-weight: 600;
    font-family: Josefin Sans, serif;
    text-decoration: none;
    align-items: center;
    height: 100%;
    padding-left: 20px;
}

.light-mode {
    color: rgb(0, 51, 179);
}

.body-transition {
    transition: background-color 0.5s ease;
}