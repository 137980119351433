@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

.typing-style {
    font-size: 25px;
    font-family: Josefin Sans, serif;
    font-weight: 400;
    line-height: 1.5;
}

.text-style {
    font-size: 20px;
    font-family: Josefin Sans, serif;
    font-weight: 350;
    line-height: 1.5;
}

.text-item-style {
    font-size: 18px;
    font-family: Josefin Sans, serif;
    font-weight: 350;
}

.text-item-title-style {
    padding-bottom: 10px;
    font-size: 20px;
    font-family: Josefin Sans, serif;
    font-weight: 400;
}

.paragraph-style {
    padding-top: 20px;
}