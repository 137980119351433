.title-style {
    font-size: 25px;
    font-family: Josefin Sans, serif;
    font-weight: 400;
    line-height: 1.5;
}

.text-style {
    font-size: 20px;
    font-family: Josefin Sans, serif;
    font-weight: 350;
    line-height: 1.5;
}

.table-head-style {
    font-size:18px;
    font-family: Josefin Sans, serif;
    font-weight: 500;
}

.table-content-style {
    font-size: 18px;
    font-family: Josefin Sans, serif;
    font-weight: 350;
}

.paragraph-style {
    padding-top: 20px;
}